import md5 from 'md5'
import { Linking, NativeModules, Platform } from 'react-native'

const SHOP_DETAIL_PAGE_REGEX = /^.*wordup\.com\.tw\/product\/(\d{1,})/

export const checkIsShopDetialPage = (url: string) => {
  return SHOP_DETAIL_PAGE_REGEX.test(url)
}

export const parseProductId = (url: string) => {
  const matches = url.match(SHOP_DETAIL_PAGE_REGEX)
  if (!matches) return null

  return matches[1]
}

export const toSafeFilename = (url = '') => {
  if (!url) return null

  const match = url.match(/.*\/(.*)\.(\w+)$/)
  if (!match) return null
  const [_, filename, extension] = match

  if (!filename || !extension) return null

  return md5(filename) + '.' + extension
}

export const isWebOnlyUrl = (url: string) => {
  const urlObject = new URL(url)

  return urlObject.searchParams.get('web_only') === 'true'
}

export const openWebOnlyUrl = (url: string) => {
  if (Platform.OS === 'android') {
    // because of expo managed, we don't directly write native codes.
    // However it seems that android still asks user which browser to open, so we can just use Linking.openURL for now

    // const { DefaultBrowserModule } = NativeModules
    // DefaultBrowserModule.openUrl(url)

    Linking.openURL(url)

    return
  } else {
    Linking.openURL(url)

    return
  }
}

export const appendSearchParamsToUrl = ({
  url,
  params,
  shouldReplaceDuplicateParam,
}: {
  url: string
  params: Record<string, string>
  shouldReplaceDuplicateParam?: boolean
}) => {
  const urlObj = new URL(url)

  const existingParams = new URLSearchParams(urlObj.searchParams)

  for (const [key, value] of Object.entries(params)) {
    if (shouldReplaceDuplicateParam) {
      existingParams.set(key, value)
    } else {
      if (!existingParams.has(key)) {
        existingParams.append(key, value)
      }
    }
  }

  const newUrl = new URL(`${urlObj.origin}${urlObj.pathname}?${existingParams}`)

  return newUrl.href
}

export const getQueryString = ({ obj = {} }: { obj: Record<string, any> }) => {
  return Object.entries(obj)
    .filter(([key, value]) => key && value?.length)
    .map(([key, value]) => `${key}=${Array.isArray(value) ? value.join(',') : value}`)
    .join('&')
}
